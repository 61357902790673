<template>
  <v-container class="mt-0 pt-0">
    <v-card>
      <v-card-title
        v-if="!items.length"
        class="d-flex justify-center text-uppercase"
      >
        Nada para sincronizar
      </v-card-title>
      <v-card-actions v-else>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-btn
              :color="!error.show ? 'primary' : 'yellow'"
              x-large
              block
              :loading="loading"
              @click="sincronizar"
            >
              <v-icon left large>{{ icons.mdiCloudUpload }}</v-icon>
              <span>sincronizar agora</span>
            </v-btn>
          </v-col>
          <v-col
            class="pa-0 d-flex justify-center"
            :class="{ errorAnimation: error.show }"
          >
            <v-card-subtitle class="pt-0 pb-0 mb-0 mt-0 font-weight-bold"
              >A sincronização deve ser realizada com conexão à
              internet</v-card-subtitle
            >
          </v-col>
        </v-row>
      </v-card-actions>
      <v-list-item v-for="item in items" :key="item.uid">
        <v-list-item-icon>
          <v-progress-circular indeterminate v-if="loading" />
          <!-- <v-icon large >
            {{ loading ? icons.mdiRefresh : null }}
          </v-icon> -->
          <v-icon large v-else :color="item.synced ? 'success' : ''">
            {{ item.synced ? icons.mdiCheckBold : icons.mdiUpload }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            #{{ item.idx }} - Data: {{ item.data }}
          </v-list-item-title>
          <v-list-item-subtitle> Tempo: {{ item.tempo }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiUpload,
  mdiCheckBold,
  mdiCloudUpload,
  mdiRefresh,
  mdiAlarmLight
} from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import { MODULE_NAME as AUTH, GET_USUARIO_MATRICULA } from '@/store/auth'
import {
  MODULE_NAME as JORNADA,
  GET_SYNC,
  LOAD,
  GET_HISTORICO_REVERSO,
  GET_JORNADA_INICIADA
} from '@/store/jornada'
import { SyncService, RealTimeService } from '@/services'
import { nanoid } from 'nanoid'
export default {
  name: 'HistoricoView',
  data() {
    return {
      loading: false,
      icons: {
        mdiUpload,
        mdiCheckBold,
        mdiCloudUpload,
        mdiRefresh,
        mdiAlarmLight
      },
      error: {
        show: false,
        message: null
      },
      nanoid
    }
  },
  computed: {
    ...mapGetters(AUTH, [GET_USUARIO_MATRICULA]),
    ...mapGetters(AUTH, {
      userRegistration: GET_USUARIO_MATRICULA
    }),
    ...mapGetters(JORNADA, {
      reverseHistoric: GET_HISTORICO_REVERSO,
      journeyStarted: GET_JORNADA_INICIADA,
      journeys: GET_SYNC
    }),
    items() {
      return this.journeys.map(item => ({ ...item, uid: nanoid() }))
    }
  },
  methods: {
    ...mapActions(JORNADA, { LOAD }),
    async sincronizar() {
      try {
        this.loading = true
        const RealTime = RealTimeService(this.$store)
        const notSyncedJourneys = this.journeys.filter(
          journey => journey.synced === false
        )
        const Service = SyncService(this.$store)
        for (const journey of notSyncedJourneys) {
          const { historico, journeyId } = journey
          if (!historico[0].synced) {
            await Service.synchronize()
          } else {
            for (const interval of historico) {
              if (!interval.synced) {
                const notSyncedJourneyId =
                  interval.journeyId || journeyId || historico.at(0).journeyId
                await RealTime.synchronize(
                  interval,
                  this.userRegistration,
                  notSyncedJourneyId
                )
              }
            }
          }
        }
      } catch (err) {
        this.error.show = true
        setTimeout(() => {
          this.error.show = false
        }, 3000)
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this[LOAD]()
    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
<style scoped>
.errorAnimation {
  background: #ffffff;
  animation: fadeBackground 3s;
  animation-fill-mode: forwards;
}
@keyframes fadeBackground {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #f02905;
  }
}
</style>
